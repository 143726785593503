@import '~antd/dist/antd.css';
@import 'variables.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
}

.top_content_container {
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 5% auto 10%;
  grid-column-gap: 10px;
}

.content_container {
  padding: 16px 24px;
}

.form_item_small {
  display: inline-block;
  margin-right: 17px;
}

// Antd Color
.ant-btn-primary {
  background: $primary-color;
  border-color: $primary-color;
  border-radius: 2px;
  &:hover,
  &:focus {
    background: $primary-color;
    border-color: $primary-color;
    opacity: 0.8;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item-selected {
  background-color: rgba(8, 158, 183, 0.2);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after, 
.ant-menu-vertical-right .ant-menu-item::after, 
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.ant-pagination-item-active,
.ant-pagination-item-active a {
  color: $primary-color;
  // border-color: $primary-color;
}

/*ant table */

th.ant-table-cell{
  height: 51px !important;
  background-color: #F3F5FF !important
}

.ant-layout-sider{
  background-color:#E5E5E5
}


.ant-input{
  border: 1px solid #F8AC3B ;
  border-radius: 4px;
}


.ant-input-disabled{
  background-color: white !important;
  border: none;
  color: black !important;
}


.login_form_container {
  margin: 0px auto;
  padding-top: 5%;
  max-width: 1400px;
  text-align: center;
  width: 25%;
}
.check_container {
  text-align: left;
}
.login_form_logo {
  margin-bottom: 50px;
  width: 129px;
  height: 131px;
}
.login_form_forgot {
  float: left;
  color: #089EB7;
  margin-top: 20px;
}

.login_form_submit {
  margin-top: 13px;
}

.text {
  margin-bottom: 20px !important;
  text-align: left;
}

.space {
  margin-bottom: 10px !important;
}

.disabled_input {
  color: palevioletred !important;
}



@media (max-width: 600px) {
  .login_form_container {
    width: 80%;
    padding-top: 10%;
  }
}


@media (min-width: 1400px) {
  .login_form_container {
    padding-top: 10%;
  }
}

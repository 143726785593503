@import "../../theme/variables.scss";

.root {
  position: relative;
  width: 209px;
  line-height: 46px;
  cursor: pointer;
  background-color: white;
  align-self: center;
}

.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding-left: 5px;
  padding-right: 5px;
}

.list {
  width: 100%;
  position: absolute;
  z-index: 2;
}

.item {
  padding-left: 15px;
  &:hover {
    background: #e5e9ff !important;
    & > span {
      color: #3d43ff !important;
    }
  }
}

.image {
  width: 10px;
  height: 5px;
}

.imageTransform {
  transform: rotate(180deg);
  width: 10px;
  height: 5px;
}

@import '../../theme/variables.scss';

.logo {
  height: 64px;
  padding: 15px;
  object-fit: contain;
  display: block;
  margin-right: auto;
}

.header {
  background: #fff !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);
  z-index: 1;
}

.content {
  background: #E5E5E5;
}

.icon {
  margin-right: 4px;
  line-height: 0;
  vertical-align: 2px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.email {
  margin-right: 25px;
}

.grid_container {
  display: grid;
  grid-template-columns: auto auto;
}

.grid_item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 23px;
}

.grid_item:first-child {
  font-size: 15px;
}
